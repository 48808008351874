import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import styles from './ArticlesCarousel.module.scss'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { AchieveTheme, Grid } from '@achieve/sunbeam'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import { getSlug } from 'utils/conversions'
import { limitStringLength } from 'utils/shared'
import { CONTENT_TYPES } from 'services/contentful/constants'

function ArticleCard({
  item: { title, subTitle, link, category, categoryLink, image, ctaText },
  index,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isXS = useViewportSmallerThan(BREAKPOINTS.md)

  const CardContent = (
    <>
      {image && (
        <div className={styles['image-container']}>
          <AchieveLink
            href={link}
            track={{
              list_name: `ARTICLE LINK`,
              position: index,
              click_id: `Achieve Web | image`,
              click_text: `image`,
              click_url: link,
            }}
          >
            <MediaImageStandard
              content={image}
              height={isXS ? 204 : 215}
              width={isXS ? 315 : 384}
              layout="fill"
            />
          </AchieveLink>
        </div>
      )}
      <>
        {!isMobile && category && categoryLink && (
          <AchieveLink
            variant="displayXS30"
            className={styles['card-category']}
            data-testid="card-category"
            href={categoryLink}
            track={{
              list_name: `ARTICLE LINK`,
              position: index,
              click_id: `Achieve Web | ${category}`,
              click_text: category,
              click_url: link,
            }}
          >
            {category}
          </AchieveLink>
        )}
        {!isMobile && category && !categoryLink && (
          <Typography
            content={category}
            variant="displayXS30"
            component="h3"
            className={styles['card-category']}
            data-testid="card-category"
          />
        )}
        {title && (
          <AchieveLink
            className={styles['title-no-decoration']}
            href={link}
            track={{
              list_name: `ARTICLE LINK`,
              position: index,
              click_id: `Achieve Web | ${title}`,
              click_text: title,
              click_url: link,
            }}
          >
            <Typography
              content={title}
              variant={isMobile ? 'displayS10' : 'displayS20'}
              component="h3"
              className={styles['card-title']}
              fontWeight="bold"
              data-testid="card-title"
            />
          </AchieveLink>
        )}
        {subTitle && (
          <Typography
            content={subTitle}
            variant={isMobile ? 'displayXS30' : 'displayS10'}
            className={styles['card-sub-title']}
            fontWeight="regular"
            data-testid="card-text"
          />
        )}
        {link && (
          <AchieveLink
            fontWeight="bold"
            variant="displayXS30"
            href={link}
            track={{
              list_name: `ARTICLE LINK`,
              position: index,
              click_id: `Achieve Web | ${ctaText}`,
              click_text: ctaText,
              click_url: link,
            }}
          >
            {ctaText}
          </AchieveLink>
        )}
      </>
    </>
  )

  return <Grid className={styles['card-container']}>{CardContent}</Grid>
}

const ArticlesCarousel = ({ content }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isSmallerXL = useViewportSmallerThan(BREAKPOINTS.xl)
  const sectionIdPrefix = `press-carousel-more-resources-carousel`
  const { title, link } = useMemoizedContentGetter(content, ['title', 'link'])
  const additionalConfiguration = content?.fields?.additionalConfiguration?.fields?.jsonContent
  const itemList = content?.fields?.relatedContent
    .filter((e) => {
      return ![
        CONTENT_TYPES.ARTICLE,
        CONTENT_TYPES.CORPORATE_BLOG,
        CONTENT_TYPES.CONSUMER_BLOG,
        CONTENT_TYPES.EXTERNAL_LINK,
      ].includes(e?.sys?.contentType?.sys?.id)
        ? false
        : true
    })
    .map((item) => {
      if (item?.sys?.contentType?.sys?.id === CONTENT_TYPES.EXTERNAL_LINK) {
        return {
          sys: item?.sys,
          title: item?.fields?.title,
          subTitle: item?.fields?.subTitle,
          link: item?.fields?.link,
          category: item?.fields?.category,
          categoryLink: item?.fields?.categoryLink,
          image: item?.fields?.image,
          ctaText: item?.fields?.ctaText,
        }
      } else {
        let shortSubTitle = item?.fields?.hook || item?.fields?.mainBody
        if (!item?.fields?.hook && item?.fields?.mainBody?.content[0]?.content[0]?.value) {
          shortSubTitle = item?.fields?.mainBody?.content[0]?.content[0]?.value
          shortSubTitle = limitStringLength(shortSubTitle, 110)
        }
        const articleUrl =
          item?.sys?.contentType?.sys?.id == 'corporateBlog'
            ? 'about/blog'
            : item?.sys?.contentType?.sys?.id == 'consumerBlog'
            ? 'dollars-sense'
            : 'learn'
        return {
          sys: item?.sys,
          title: item?.fields?.title,
          subTitle: shortSubTitle,
          link: `/${articleUrl}/${getSlug(item?.fields?.primaryCategory)}/${item?.fields?.slug}`,
          category: item?.fields?.primaryCategory,
          categoryLink: `/${articleUrl}/${getSlug(item?.fields?.primaryCategory)}`,
          image: item?.fields?.mainImage?.fields?.mediaContent,
          ctaText: 'Read the article',
        }
      }
    })
  const variant = {
    title: isMobile ? 'displayS20' : 'displayM20',
    titleFontWeight: 'medium',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  const carouselEnabledByItemCount = itemList.length > 3

  return (
    <>
      {content?.fields?.relatedContent.length > 0 && (
        <Section
          contain={false}
          className={styles['section']}
          backgroundColor={
            additionalConfiguration?.backgroundColor === 'blue'
              ? AchieveTheme?.sb?.colors?.primary?.highlightBlue
              : null
          }
        >
          <TitleDivider
            className={styles['title-divider']}
            title={title}
            isMobile={isMobile}
            data-testid={`featured-press-carousel-title`}
            variants={variant}
          />
          <Carousel
            carouselItems={itemList}
            className={styles['articles-carousel-wrapper']}
            classNameContainer={styles['articles-carousel-container']}
            classNameItems={styles['articles-carousel-items']}
            itemComponent={ArticleCard}
            variant="auto"
            idPrefix={sectionIdPrefix}
            disableArrows={isSmallerXL || carouselEnabledByItemCount ? false : true}
            withIndicators={false}
            classNameControls={styles['carousel-wrapper-controls']}
          />
          {link && (
            <AchieveLink
              href={link.linkHref}
              track={{
                list_name: `ARTICLE LINK`,
              }}
              fontWeight="bold"
              variant="displayXS30"
              className={styles['link']}
            >
              {link?.linkText?.content[0]?.content[0]?.value}
            </AchieveLink>
          )}
        </Section>
      )}
    </>
  )
}
export { ArticlesCarousel }
export default ArticlesCarousel
